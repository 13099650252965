import {
  Avatar,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Typography, Stack } from "@mui/material";
import {
  LoadingButtonComponent,
  MediaUploadComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import { ChangeEvent, useEffect, useState } from "react";
import MediaAssets from "../../../../assets/index";
import styles from "./Opportunity.module.css";
import { shouldNumberOnly } from "utils/ValidationUtils";
import ExpectedGrowth from "./ExpectedGrowth";
import CurrentInfraStory from "./CurrentInfraStory";
import UpcomingInfraStory from "./UpcomingInfraStory";
import TourismAround from "./TourismAround";
import ProjectAmenities from "./ProjectAmenities";
import PageFooter from "./PageFooter";
import AboutTheProject from "./AboutTheProject";
import { IInitinalValueProjectContent } from "../InitinalValuesProjectContent";
import { getIn, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import FileUploadActions from "redux-container/file-upload-S3/fileUploadRedux";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import ProjectBrouchure from "./ProjectBrouchure";

export default function OpportunityDoc() {
  const dispatch = useDispatch();
  const whyToInvestMedia = `opportunityDoc.whyToInvestMedia.value.url`;
  const BannerMedia = `opportunityDoc.bannerImage.value.url`;
  const projectDocMedia = `opportunityDoc.brochure.value.url`;

  const [imageUploadToOpportunityDoc, setImageUploadToOpportunityDoc] =
    useState("");
  const [pdfName, setPdfName] = useState("");
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const handleOpenConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    handleBlur,
    touched,
    errors,
  } = useFormikContext<IInitinalValueProjectContent>();

  const { imageDetails, isLoading, isSuccess, isDelete } = useSelector(
    (state: any) => state.fileUpload
  );

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  //upload
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (imageUploadToOpportunityDoc === whyToInvestMedia) {
        const fileNameOfWhyToInvestPreview = `opportunityDoc.whyToInvestMedia.value.url`;
        setFieldValue(fileNameOfWhyToInvestPreview, imageDetails?.url);
        setImageUploadToOpportunityDoc("");
      } else if (BannerMedia === imageUploadToOpportunityDoc) {
        const fileNameOfBannerPreview = `opportunityDoc.bannerImage.value.url`;
        setFieldValue(fileNameOfBannerPreview, imageDetails?.url);
        setImageUploadToOpportunityDoc("");
      } else if (projectDocMedia === imageUploadToOpportunityDoc) {
        const fileNameOfprojectDocPreview = `opportunityDoc.brochure.value.url`;
        setFieldValue(fileNameOfprojectDocPreview, imageDetails?.url);
        setImageUploadToOpportunityDoc("");
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isSuccess", false));
  }, [isSuccess]);

  //delete
  useEffect(() => {
    async function getAllInsightsCreationFunction() {
      if (imageUploadToOpportunityDoc === whyToInvestMedia) {
        const empty = "";
        const imageDeleteOfWhyToInvest = `opportunityDoc.whyToInvestMedia.value.url`;
        setFieldValue(imageDeleteOfWhyToInvest, empty);
        setImageUploadToOpportunityDoc("");
      } else if (BannerMedia === imageUploadToOpportunityDoc) {
        const empty = "";
        const imageDeleteOfBannerImage = `opportunityDoc.bannerImage.value.url`;
        setFieldValue(imageDeleteOfBannerImage, empty);
        setImageUploadToOpportunityDoc("");
      } else if (projectDocMedia === imageUploadToOpportunityDoc) {
        const empty = "";
        const imageDeleteOfProjectDocImage = `opportunityDoc.brochure.value.url`;
        setFieldValue(imageDeleteOfProjectDocImage, empty);
        setImageUploadToOpportunityDoc("");
      }
    }
    getAllInsightsCreationFunction();
    dispatch(FileUploadActions.setUploadFileState("isDelete", false));
  }, [isDelete]);

  const handleWhyToInvestMedia = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;
    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setImageUploadToOpportunityDoc(MediaId);
      };
      reader.readAsArrayBuffer(file);
      const fileNameOfMedia = `opportunityDoc.whyToInvestMedia.name`;
      const fileSizeOfMedia = `opportunityDoc.whyToInvestMedia.value.size`;
      const keyOfMedia = `opportunityDoc.whyToInvestMedia.key`;

      setFieldValue(keyOfMedia, file?.name);
      setFieldValue(fileNameOfMedia, file?.name);
      setFieldValue(fileSizeOfMedia, file?.size);
    }
    return;
  };
  const handleProjectDocMedia = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target?.id;
    if (file) {
      setPdfName(file?.name);
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setImageUploadToOpportunityDoc(MediaId);
      };
      reader.readAsArrayBuffer(file);
      const fileNameOfMedia = `opportunityDoc.brochure.name`;
      const fileSizeOfMedia = `opportunityDoc.brochure.value.size`;
      const keyOfMedia = `opportunityDoc.brochure.key`;

      setFieldValue(keyOfMedia, file?.name);
      setFieldValue(fileNameOfMedia, file?.name);
      setFieldValue(fileSizeOfMedia, file?.size);
    }
    return;
  };
  const handleBannerImage = (event: any) => {
    const file = event?.target?.files[0];
    const MediaId = event?.target.id;

    if (file) {
      const data = {
        name: file?.name,
        file,
        urlType: "upload",
        binary: "",
      };
      const reader = new FileReader();
      reader.onload = (event: any) => {
        data.binary = event.target.result;
        dispatch(FileUploadActions.fileUploadRequest(data));
        setImageUploadToOpportunityDoc(MediaId);
      };
      reader.readAsArrayBuffer(file);

      const fileNameOfMedia = `opportunityDoc.bannerImage.name`;
      const fileSizeOfMedia = `opportunityDoc.bannerImage.value.size`;
      const keyOfMedia = `opportunityDoc.bannerImage.key`;

      setFieldValue(keyOfMedia, file?.name);
      setFieldValue(fileNameOfMedia, file?.name);
      setFieldValue(fileSizeOfMedia, file?.size);
    }
    return;
  };

  const handleCancelWhyToIvestImage = () => {
    // handleOpenConfirmDialog();
    const whyToInvestMedia = `opportunityDoc.whyToInvestMedia.value.url`;
    setImageUploadToOpportunityDoc(whyToInvestMedia);
  };

  const handleCancelProjectDocImage = () => {
    // handleOpenConfirmDialog();
    const projectDocMedia = `opportunityDoc.brochure.value.url`;
    setImageUploadToOpportunityDoc(projectDocMedia);
  };
  const handleCancelBannerImage = () => {
    // handleOpenConfirmDialog();
    const bannerImage = `opportunityDoc.bannerImage.value.url`;
    setImageUploadToOpportunityDoc(bannerImage);
  };

  // Confirm Open/close
  // const handleCloseConfirmDialog = () => {
  //   dispatch(FileUploadActions.setUploadFileState("isDeleteConfirm", false));
  // };

  const handleConfirmDeleteWhyToInvestMedia = (values: any) => {
    handleCloseConfirmDialog();
    let fileName = values?.opportunityDoc?.whyToInvestMedia?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  const handleConfirmDeleteProjectDocMedia = (values: any) => {
    handleCloseConfirmDialog();
    let fileName = values?.opportunityDoc?.brochure?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  const handleConfirmDeleteBannerImage = (values: any) => {
    handleCloseConfirmDialog();
    let fileName = values?.opportunityDoc?.bannerImage?.name;
    dispatch(FileUploadActions.fileDeleteRequest(fileName));
  };

  return (
    <div>
      <Grid container spacing={2} mt={3} mb={3} sx={{ marginLeft: "20px" }}>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <TextFieldComponent
            required
            label={"Section Heading"}
            type="text"
            placeholder="Enter"
            name={"opportunityDoc.sectionHeading.heading"}
            inputProps={{
              maxLength: 25,
            }}
            onBlur={handleBlur}
            value={values?.opportunityDoc?.sectionHeading?.heading}
            onChange={handleChange}
            // fieldhelpertext={
            //   getIn(touched, `opportunityDoc.sectionHeading.heading`) &&
            //   getIn(errors, `opportunityDoc.sectionHeading.heading`)
            // }
            // error={Boolean(
            //   getIn(errors, `opportunityDoc.sectionHeading.heading`) &&
            //     getIn(touched, `opportunityDoc.sectionHeading.heading`)
            // )}

            fieldhelpertext={
              getIn(touched, "opportunityDoc.sectionHeading.heading") &&
              getIn(errors, "opportunityDoc.sectionHeading.heading")
            }
            error={Boolean(
              getIn(errors, "opportunityDoc.sectionHeading.heading") &&
                getIn(touched, "opportunityDoc.sectionHeading.heading")
            )}
            disabled={isViewMode}
          />
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3}>
          <TextFieldComponent
            required
            label={"Sub Heading"}
            type="text"
            placeholder="Enter"
            name={"opportunityDoc.sectionHeading.subHeading"}
            inputProps={{
              maxLength: 25,
            }}
            onBlur={handleBlur}
            value={values?.opportunityDoc?.sectionHeading?.subHeading}
            onChange={handleChange}
            fieldhelpertext={
              getIn(touched, `opportunityDoc.sectionHeading.subHeading`) &&
              getIn(errors, `opportunityDoc.sectionHeading.subHeading`)
            }
            error={Boolean(
              getIn(errors, `opportunityDoc.sectionHeading.subHeading`) &&
                getIn(touched, `opportunityDoc.sectionHeading.subHeading`)
            )}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>

      <Divider />
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Why to Invest Card</Typography>
        <IconButton>
          <Tooltip
            arrow
            title={
              "This card will redirect the user to the “Why invest” page on Customer App"
            }
            placement="top-start"
          >
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={4} md={4} lg={4.5} xl={3.5} p={"1.5rem"}>
            <MediaUploadComponent
              ondrop={handleChange}
              loading={
                isLoading && imageUploadToOpportunityDoc === whyToInvestMedia
              }
              name={`opportunityDoc.whyToInvestMedia.value.url`}
              onChange={(event: any) => {
                handleWhyToInvestMedia(event);
              }}
              onBlur={handleBlur}
          previewimage={values?.opportunityDoc?.whyToInvestMedia?.value?.url}
              cancelimage={() => {
                handleCancelWhyToIvestImage();
                handleConfirmDeleteWhyToInvestMedia(values);
              }}
              fieldhelpertext={
                getIn(touched, "opportunityDoc.whyToInvestMedia.value.url") &&
                getIn(errors, "opportunityDoc.whyToInvestMedia.value.url")
              }
              disabled={isViewMode}
            />
          </Grid>
      <Divider sx={{ mt: "0.5rem" }} />
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Page Banner Image</Typography>
        <IconButton>
          <Tooltip
            arrow
            title={
              "This banner image will appear on top of the “Why Invest “ Page on Customer App"
            }
            placement="top-start"
          >
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={4} md={4} lg={4.5} xl={3.5} p={"1.5rem"}>
        <MediaUploadComponent
          ondrop={handleChange}
          loading={isLoading && BannerMedia === imageUploadToOpportunityDoc}
          name={"opportunityDoc.bannerImage.value.url"}
          onChange={(event: any) => {
            handleBannerImage(event);
          }}
          onBlur={handleBlur}
          previewimage={values?.opportunityDoc?.bannerImage?.value?.url}
          cancelimage={() => {
            handleCancelBannerImage();
            handleConfirmDeleteBannerImage(values);
          }}
          fieldhelpertext={
            getIn(touched, "opportunityDoc.bannerImage.value.url") &&
            getIn(errors, "opportunityDoc.bannerImage.value.url")
          }
          disabled={isViewMode}
        />
      </Grid>
      <Divider sx={{ mt: "0.5rem" }} />
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Expected Growth</Typography>
        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isEscalationGraphActive"}
          checked={values?.opportunityDoc?.isEscalationGraphActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />

        <IconButton>
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the Customer App"}
            placement="top-start"
          >
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>

        {/* Expected Growth toggle for web */}
        <Typography>Expected Growth Web</Typography>
        <FormControlLabel
          className="switch-form-label"
          name={"opportunityDoc.isEscalationGraphWebActive"}
          checked={values?.opportunityDoc?.isEscalationGraphWebActive}
          onChange={handleChange}
          control={<SwitchComponent />}
          label=""
          labelPlacement="start"
          disabled={isViewMode}
        />

        <IconButton>
          <Tooltip
            arrow
            title={"Toggle is to show/hide this section on the website"}
            placement="top-start"
          >
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid container p={3} spacing={2}>
        <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
          <TextFieldComponent
            required
            type="text"
            label="Section Heading"
            placeholder="Enter"
            onBlur={handleBlur}
            name="opportunityDoc.escalationGraph.title"
            value={values?.opportunityDoc?.escalationGraph?.title}
            onChange={handleChange}
            fieldhelpertext={
              getIn(touched, "opportunityDoc.escalationGraph.title") &&
              getIn(errors, "opportunityDoc.escalationGraph.title")
            }
            error={Boolean(
              getIn(errors, "opportunityDoc.escalationGraph.title") &&
                getIn(touched, "opportunityDoc.escalationGraph.title")
            )}
            disabled={isViewMode}
          />
        </Grid>
        {/* <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
          <TextFieldComponent
            type="text"
            disabled
            label="Project Estimated Appreciation"
            placeholder="Enter"
            onBlur={handleBlur}
            name="opportunityDoc.escalationGraph.projectEstimatedAppreciation"
            value={
              values?.opportunityDoc?.escalationGraph
                ?.projectEstimatedAppreciation
            }
            onChange={handleChange}
            fieldhelpertext={
              getIn(
                touched,
                "opportunityDoc.escalationGraph.projectEstimatedAppreciation"
              ) &&
              getIn(
                errors,
                "opportunityDoc.escalationGraph.projectEstimatedAppreciation"
              )
            }
            error={Boolean(
              getIn(
                errors,
                "opportunityDoc.escalationGraph.projectEstimatedAppreciation"
              ) &&
                getIn(
                  touched,
                  "opportunityDoc.escalationGraph.projectEstimatedAppreciation"
                )
            )}
          />
        </Grid> */}
      </Grid>
      <Grid container p={3} spacing={2}>
        <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
          <TextFieldComponent
            required
            type="text"
            label="X-Axis Display Name"
            placeholder="Enter"
            name="opportunityDoc.escalationGraph.xAxisDisplayName"
            value={values?.opportunityDoc?.escalationGraph?.xAxisDisplayName}
            onChange={handleChange}
            onBlur={handleBlur}
            inputProps={{ maxLength: 40 }}
            fieldhelpertext={
              getIn(
                touched,
                "opportunityDoc.escalationGraph.xAxisDisplayName"
              ) &&
              getIn(errors, "opportunityDoc.escalationGraph.xAxisDisplayName")
            }
            error={Boolean(
              getIn(
                errors,
                "opportunityDoc.escalationGraph.xAxisDisplayName"
              ) &&
                getIn(
                  touched,
                  "opportunityDoc.escalationGraph.xAxisDisplayName"
                )
            )}
            disabled={isViewMode}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={4} xl={3}>
          <TextFieldComponent
            required
            type="text"
            label="Y- Axis Display Name"
            placeholder="Enter"
            name="opportunityDoc.escalationGraph.yAxisDisplayName"
            value={values?.opportunityDoc?.escalationGraph?.yAxisDisplayName}
            onChange={handleChange}
            inputProps={{ maxLength: 40 }}
            onBlur={handleBlur}
            fieldhelpertext={
              getIn(
                touched,
                "opportunityDoc.escalationGraph.yAxisDisplayName"
              ) &&
              getIn(errors, "opportunityDoc.escalationGraph.yAxisDisplayName")
            }
            error={Boolean(
              getIn(
                errors,
                "opportunityDoc.escalationGraph.yAxisDisplayName"
              ) &&
                getIn(
                  touched,
                  "opportunityDoc.escalationGraph.yAxisDisplayName"
                )
            )}
            disabled={isViewMode}
          />
        </Grid>
      </Grid>
      <ExpectedGrowth />
      <CurrentInfraStory />
      <UpcomingInfraStory />
      <TourismAround />
      <AboutTheProject />
      <ProjectAmenities />
      <Stack justifyContent={"flex-start"} padding={2} spacing={1}>
        <Typography>Project Doc</Typography>
        <IconButton>
          <Tooltip
            arrow
            title={
              "This card will redirect the user to the “Why invest” page on Customer App"
            }
            placement="top-start"
          >
            <Avatar
              className={styles["Avator"]}
              src={MediaAssets.ic_info}
              variant="rounded"
            />
          </Tooltip>
        </IconButton>
      </Stack>
      <Grid item xs={4} md={4} lg={4.5} xl={3.5} p={"1.5rem"}>
        <MediaUploadComponent
          ondrop={handleChange}
          loading={isLoading && imageUploadToOpportunityDoc === projectDocMedia}
          name={`opportunityDoc.brochure.value.url`}
          value={values?.opportunityDoc?.brochure?.name}
          onChange={(event: any) => {
            // handleWhyToInvestMedia(event);
            handleProjectDocMedia(event);
          }}
          onBlur={handleBlur}
          previewimage={values?.opportunityDoc?.brochure?.value?.url}
          cancelimage={() => {
            // handleCancelWhyToIvestImage();
            handleCancelProjectDocImage();
            // handleConfirmDeleteWhyToInvestMedia(values);
            handleConfirmDeleteProjectDocMedia(values);
          }}
          fieldhelpertext={
            getIn(touched, "opportunityDoc.brochure.value.url") &&
            getIn(errors, "opportunityDoc.brochure.value.url")
          }
          disabled={isViewMode}
        />
        {/* <ProjectBrouchure /> */}
      </Grid>
      <PageFooter />
      {/* <Grid container padding={2} justify-content={"flex-end"}>
        <Grid item xs={12} textAlign="right">
          <LoadingButtonComponent type="submit">Save</LoadingButtonComponent>
        </Grid>
      </Grid> */}
      {/* <ConfirmationDialog
        shouldOpen={isConfirmDeleteDialogOpen}
        title="Delete Insights Creation Media?"
        content="This action will delete the Insights Creation Media. Are you sure you want to continue?"
        okText="Delete"
        cancelHandler={handleCloseConfirmDialog}
        okHandler={() => handleConfirmDelete(values)}
      /> */}
    </div>
  );
}
