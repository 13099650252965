import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  TablePagination,
  IconButton,
  Tooltip,
  Stack,
  Grid,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteConstants } from "routes/RouteConstants";
import { Constants } from "constants/Constants";
import { formatDateTime } from "utils/DateFormatterUtils";
import {
  // ProjectStatusTypeEnumUtils,
  StatusTypeEnumUtils,
  TestimonialStatusTypeEnum,
} from "enumerations/StatusTypeEnum";
import {
  AutocompleteComponent,
  ButtonComponent,
  TextFieldComponent,
} from "components/ui-elements";
import {
  ActionTypeEnumPromise,
  ActionTypeEnumUtilsPromise,
  ProjectActionTypeEnumUtils,
} from "enumerations/ActionTypeEnum";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";
import Loader from "components/loader/Loader";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import { ProjectStatusTypeEnumUtils } from "enumerations/ProjectStatusTypeEnum";
import { SortingOrder } from "enumerations/SortingOrderTypeEnum";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FormsListActons from "../../../redux-container/form-list/FormListRedux";

const actionDispatch = (dispatch: any) => {
  return {
    getForms: (params: any) =>
      dispatch(FormsListActons.formsListRequest(params)),
    updateFormStatusRequest: (params: any) =>
      dispatch(FormsListActons.updateFormStatusRequest(params)),
    deleteFormsRequest: (params: any) =>
      dispatch(FormsListActons.deleteFormsRequest(params)),
    setFormListState: async (key: any, value: any) => {
      dispatch(FormsListActons.setFormListState(key, value));
    },
  };
};
const PublishedForm = ({ listHeading, searchKey, tabValue }: any) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const users = useSelector((state: any) => state?.users?.usersForFilters);

  // const statuses = StatusTypeEnumUtils.getStatusTypeEnums();
  const statuses = ProjectStatusTypeEnumUtils.getProjectStatusTypeEnums();

  const actions = ProjectActionTypeEnumUtils.getProjectsActionTypeEnums();

  const {
    getForms,
    setFormListState,
    deleteFormsRequest,
    updateFormStatusRequest,
  } = actionDispatch(useDispatch());

  const formList = useSelector((state: any) => state?.formsList?.forms);
  const CRMProjects = useSelector((state: any) => state?.formsList?.projects);
  const launches = useSelector((state: any) => state?.formsList?.launches);

  const totalCount = useSelector((state: any) => state?.formsList?.totalCount);
  const [selectedForms, setSelectedForms] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>("");
  const [selectedCRMProject, setSelectedCRMProject] = useState<any>(null);
  const [selectedLaunch, setSelectedLaunch] = useState<any>(null);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState<any>();
  const [selectedAction, _setSelectedAction] = useState("");
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);
  const isLoading = useSelector((state: any) => state?.formsList?.isLoading);
  const isSuccess = useSelector((state: any) => state?.formsList?.isSuccess);
  const message = useSelector((state: any) => state?.formsList?.message);
  const shouldOpenFlashDialog = useSelector(
    (state: any) => state?.formsList?.shouldOpenFlashDialog
  );
  const [columnSort, setColumnSort] = useState({
    sortingKey: "",
    sortingPriority: "",
    isActiveSortingColumn: "",
  });
  const fetchedAllLaunchPhasesData = useSelector(
    (state: any) => state.projectLaunchPhases?.fetchedAllLaunchPhasesData
  );

  // Checking and Removing Duplicate CRM Project Name.
  const crmProjectNamesOfPublished = CRMProjects?.filter(
    (option: any, index: any, element: any) =>
      element.findIndex((item: any) => item?.name === option?.name) === index
  );

  const publishedFormpage = useSelector(
    (state: any) => state?.formsList?.publishedFormpage
  );
  const publishedFormRowsPerPage = useSelector(
    (state: any) => state?.formsList?.publishedFormRowsPerPage
  );

  useEffect(() => {
    // if (shouldOpenFlashDialog) {
    const param = {
      status: selectedStatus,
      name: selectedCRMProject?.name,
      launchName: selectedLaunch?.launchName,
      // index: publishedFormpage + 1,
      // size: publishedFormRowsPerPage,
      createdBy: selectedCreatedBy?.id,
      searchKey: searchKey,

      sortingKey: columnSort.sortingKey,
      sortingPriority: columnSort.sortingPriority,
    };
    getForms(param);
    setSelectedForms([]);
    // }
    return () => {
      getForms({});
    };
  }, [
    selectedStatus,
    selectedCRMProject,
    selectedLaunch,
    publishedFormpage,
    publishedFormRowsPerPage,
    isSuccess,
    shouldOpenFlashDialog,
    selectedCreatedBy,
    tabValue == 2 ? searchKey : null,
    columnSort,
  ]);

  // useEffect(() => {
  //   getForms({});
  // }, []);
  useEffect(() => {
    if (location.state?.isNavigationFromSidemenu) {
      setFormListState("publishedFormpage", 1);
      setFormListState("publishedFormRowsPerPage", 5);
    }

    return () => {};
  }, []);

  const handleViewIconClick = (form: any) => {
    navigate(`${RouteConstants.formLists}/${form.id}`, {
      state: { form: form, isEditMode: true, isViewMode: true, isNew: false },
    });
  };
  const handleEditIconClick = (form: any) => {
    navigate(`${RouteConstants.formLists}/${form.id}`, {
      state: { form: form, isEditMode: true },
    });
  };

  const handleResetFilter = () => {
    setSelectedStatus(null);
    setSelectedCRMProject(null);
    setSelectedCreatedBy(null);
    setSelectedLaunch(null);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFormListState("publishedFormpage", newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormListState("publishedFormRowsPerPage", Number(event.target.value));
    setFormListState("publishedFormpage", 0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds: any = formList.map((data: any) => data.id);
      setSelectedForms(newSelecteds);
      return;
    }
    setSelectedForms([]);
  };
  const handleClick = (event: React.MouseEvent<unknown>, id: any) => {
    const selectedIndex = selectedForms.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedForms, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedForms.slice(1));
    } else if (selectedIndex === selectedForms?.length - 1) {
      newSelected = newSelected.concat(selectedForms.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedForms.slice(0, selectedIndex),
        selectedForms.slice(selectedIndex + 1)
      );
    }
    setSelectedForms(newSelected);
  };
  const isSelected = (id: any) => selectedForms.indexOf(id) !== -1;

  const handleActionChange = (event: SelectChangeEvent) => {
    const actionSelected = event.target.value;

    switch (Number(actionSelected)) {
      case ActionTypeEnumPromise.Export:
        // handleExportAsExcel();
        break;
      case ActionTypeEnumPromise.Delete:
        handleDeleteForms();
        break;
      case ActionTypeEnumPromise.MarkAsPublished:
        updateForms(String(TestimonialStatusTypeEnum.Published));
        break;
      case ActionTypeEnumPromise.MarkAsInactive:
        updateForms(String(TestimonialStatusTypeEnum.Inactive));
        break;
    }
  };

  const updateForms = async (status: string) => {
    if (selectedForms?.length === 0) {
      alert("You have not selected any Forms");
      return;
    }
    const formsToBeUpdated = {
      paymentFormIds: selectedForms,
      status: status,
    };
    updateFormStatusRequest(formsToBeUpdated);
  };
  const deleteForms = () => {
    const formsToBeDeleted = {
      paymentFormIds: selectedForms,
    };
    deleteFormsRequest(formsToBeDeleted);
  };
  const handleDeleteForms = () => {
    if (selectedForms?.length === 0) {
      alert("You have not selected any Forms");
      return;
    }
    setIsConfirmDeleteDialogOpen(true);
  };
  const handleCloseFlashMessageDialog = () => {
    setFormListState("shouldOpenFlashDialog", false);
  };
  const handleCloseConfirmDialog = () => {
    setIsConfirmDeleteDialogOpen(false);
  };
  const okHandler = async () => {
    deleteForms();
    setIsConfirmDeleteDialogOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Stack>
            <Grid container spacing={2} md={10} lg={10} xl={10}>
              <Grid
                item
                sx={{ marginRight: -"40px" }}
                md={1}
                lg={1}
                xl={1}
                className="margin-left-10 filter-heading-holder"
              >
                <span className="filter-heading">Filters:</span>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={crmProjectNamesOfPublished}
                    getOptionLabel={(option: any) => option?.name}
                    label="CRM Project Name"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCRMProject(newValue);
                    }}
                    value={selectedCRMProject}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={launches}
                    getOptionLabel={(option: any) => option?.launchName}
                    label="Launch"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedLaunch(newValue);
                    }}
                    value={selectedLaunch}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <TextFieldComponent
                    select
                    value={selectedStatus}
                    label="Status"
                    onChange={(event: any) => {
                      setSelectedStatus(event.target.value);
                    }}
                  >
                    {statuses.map((status: any) => {
                      if (status.value == "1003" || status.value == "1004") {
                        return;
                      }
                      return (
                        <MenuItem value={status.value} key={status.value}>
                          {status.label}
                        </MenuItem>
                      );
                    })}
                  </TextFieldComponent>
                </FormControl>
              </Grid>
              <Grid item md={2} lg={2} xl={2}>
                <FormControl className="filter-dropdown">
                  <AutocompleteComponent
                    options={users}
                    getOptionLabel={(option: any) =>
                      option?.isDuplicated
                        ? `${option?.firstName} ${option?.lastName} (#-${option?.id})`
                        : `${option?.firstName} ${option?.lastName}`
                    }
                    value={selectedCreatedBy}
                    label="Created by"
                    onChange={(
                      event: React.SyntheticEvent,
                      newValue: any | null
                    ) => {
                      setSelectedCreatedBy(newValue);
                    }}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                  />
                </FormControl>
              </Grid>

              {selectedCRMProject ||
              selectedStatus ||
              selectedCreatedBy ||
              selectedLaunch ? (
                <Grid
                  item
                  md={0.5}
                  lg={0.5}
                  xl={0.5}
                  className="filter-heading-holder"
                  style={{ position: "relative" }}
                >
                  <span
                    onClick={handleResetFilter}
                    className="clear-filter"
                    style={{ position: "absolute", top: 32 }}
                  >
                    Clear
                  </span>
                </Grid>
              ) : null}
            </Grid>
            <div>
              <FormControl className="action-dropdown">
                <TextFieldComponent
                  select
                  value={selectedAction}
                  label="Actions"
                  onChange={handleActionChange}
                >
                  {actions.map((action: any) => {
                    if (action.value == 1) {
                      return;
                    }
                    return (
                      <MenuItem value={action.value} key={action.value}>
                        {action.label}
                      </MenuItem>
                    );
                  })}
                </TextFieldComponent>
              </FormControl>
            </div>
          </Stack>
          <TableContainer
            component={Paper}
            className="table-container"
            style={{ height: window.innerHeight - 475 }}
          >
            <Table style={{ position: "relative" }}>
              <TableHead>
                <TableRow className="table-header-row">
                  <TableCell className="table-header-text" padding="checkbox">
                    <Checkbox
                      className="active-checkbox"
                      data-testid="table-head-checkbox"
                      color="primary"
                      indeterminate={
                        selectedForms?.length > 0 &&
                        selectedForms?.length < formList?.length
                      }
                      checked={
                        formList?.length > 0 &&
                        selectedForms?.length === formList?.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {listHeading.map(
                    (item: (typeof listHeading)[0], index: number) => (
                      <TableCell
                        key={index}
                        className="table-header-text"
                        // style={item.isSortingColumn ? { minWidth: 150 } : {}}
                      >
                        {item.heading}
                        {item.isSortingColumn ? (
                          <>
                            {columnSort.sortingPriority ===
                              SortingOrder.Descending &&
                            columnSort.isActiveSortingColumn ===
                              item.heading ? (
                              <IconButton
                                onClick={() => {
                                  setColumnSort({
                                    sortingKey: item.sortingKey,
                                    sortingPriority: SortingOrder.Ascending,
                                    isActiveSortingColumn: item.heading,
                                  });
                                  // if (page !== 0) {
                                  //   setCustomersState("page", 0);
                                  // }
                                }}
                              >
                                <ArrowDropUpIcon
                                  fontSize={"large"}
                                  style={{
                                    position: "relative",
                                    // top: "16px",
                                    right: "10px",
                                    fontSize: "3rem",
                                    color:
                                      columnSort.isActiveSortingColumn ===
                                      item.heading
                                        ? "#676ac0"
                                        : "rgb(0 0 0 / 87%)",
                                  }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  setColumnSort({
                                    sortingKey: item.sortingKey,
                                    sortingPriority: SortingOrder.Descending,
                                    isActiveSortingColumn: item.heading,
                                  });
                                  // if (page !== 0) {
                                  //   setCustomersState("page", 0);
                                  // }
                                }}
                              >
                                <ArrowDropDownIcon
                                  fontSize={"large"}
                                  style={{
                                    position: "relative",
                                    // top: "10px",
                                    right: "10px",
                                    fontSize: "3rem",
                                    color:
                                      columnSort.isActiveSortingColumn ===
                                      item.heading
                                        ? "#676ac0"
                                        : "rgb(0 0 0 / 87%)",
                                  }}
                                />
                              </IconButton>
                            )}
                          </>
                        ) : null}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              {formList?.length > 0 ? (
                <>
                  <TableBody>
                    {formList?.map((form: any) => {
                      const isItemSelected: any = isSelected(form?.id);
                      console.log("form", form);

                      return (
                        <TableRow
                          key={form.id}
                          className="table-data-row"
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event: any) =>
                                handleClick(event, form?.id)
                              }
                              className="active-checkbox"
                              checked={isItemSelected}
                            />
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {`#ID-${form.id}`}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {form?.crmLaunchPhase?.launchName
                              ? form?.crmLaunchPhase?.launchName
                              : "----"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {form?.crmProject?.name
                              ? form?.crmProject?.name
                              : "----"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                          >
                            {form?.crmLaunchPhase?.projectContents[0]
                              ?.launchName
                              ? form?.crmLaunchPhase?.projectContents[0]
                                  ?.launchName
                              : "----"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 170 }}
                            align="center"
                          >
                            {form?.bookingAmount
                              ? `₹ ${form?.bookingAmount}`
                              : "---"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 200 }}
                          >
                            {form.createdAt
                              ? formatDateTime(
                                  form.createdAt,
                                  Constants.dateFormatDDmmYYYY
                                )
                              : "-"}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {form?.paymentFormCreatedBy?.firstName}{" "}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {form?.paymentFormUpdatedBy?.firstName}{" "}
                          </TableCell>
                          <TableCell
                            className="table-data-text"
                            style={{ minWidth: 120 }}
                          >
                            {form?.status
                              ? ProjectStatusTypeEnumUtils.getEnumText(
                                  form?.status
                                )
                              : "-"}
                          </TableCell>
                          <TableCell style={{ minWidth: 200 }}>
                            <Stack>
                              <Box>
                                <Tooltip title="Edit" placement="top">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => handleEditIconClick(form)}
                                  >
                                    <ModeEditOutlineOutlinedIcon className="table-action-icon edit-icon" />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="View" placement="top">
                                  <IconButton
                                    aria-label="view"
                                    onClick={() => handleViewIconClick(form)}
                                  >
                                    <VisibilityOutlinedIcon className="table-action-icon view-icon" />
                                  </IconButton>
                                </Tooltip>
                              </Box>

                              {form?.crmLaunchPhase?.launchName && (
                                <Tooltip
                                  title={`${Constants.paymentsFrontEndProdBaseUrl}${form?.crmLaunchPhase?.crmId}`}
                                  placement="top"
                                >
                                  <IconButton
                                    sx={{
                                      color: "var(--quinary-theme-color)",
                                      fontSize: "1rem",
                                      fontFamily: "Jost-Regular",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    <a
                                      href={`${Constants.paymentsFrontEndProdBaseUrl}${form?.crmLaunchPhase?.crmId}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Preview
                                    </a>
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </>
              ) : (
                <div className="no-record">No Forms Found</div>
              )}
            </Table>
          </TableContainer>
          {formList?.length > 0 ? (
            <TablePagination
              // className={styles["table-pagination"]}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={
                totalCount
                  ? totalCount
                  : formList?.length
                  ? formList?.length
                  : 0
              }
              rowsPerPage={publishedFormRowsPerPage}
              page={publishedFormpage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
          <FlashMessageDialog
            shouldOpen={shouldOpenFlashDialog}
            content={message}
            isSuccess={isSuccess}
            cancelHandler={handleCloseFlashMessageDialog}
          />
          <ConfirmationDialog
            shouldOpen={isConfirmDeleteDialogOpen}
            title="Delete Forms?"
            content={`This action will delete the  ${selectedForms.length} Forms. Are you sure you want to continue?`}
            okText="Delete"
            cancelHandler={handleCloseConfirmDialog}
            okHandler={okHandler}
          />
        </>
      )}
    </>
  );
};

export default PublishedForm